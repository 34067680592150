import { noop } from 'lodash';
import {
  getScrollXOf,
  getScrollYOf,
  setScrollXOf,
  setScrollYOf,
} from '@doltech/core/lib/hooks/useScrollElement';

export const ScrollType = {
  VERTICAL: 0,
  HORIZONTAL: 1,
} as const;

export type ScrollType = typeof ScrollType[keyof typeof ScrollType];

const easeInOutQuad = (
  currentTime: number,
  start: number,
  change: number,
  duration: number
): number => {
  let newCurrentTime = currentTime;
  newCurrentTime /= duration / 2;

  if (newCurrentTime < 1) {
    return (change / 2) * newCurrentTime * newCurrentTime + start;
  }

  newCurrentTime -= 1;
  return (-change / 2) * (newCurrentTime * (newCurrentTime - 2) - 1) + start;
};

interface SmoothScrollOptions {
  duration: number;
  element: HTMLElement;
  to: number;
  type: ScrollType;
}

const getCurrentPositionBy = (element: HTMLElement | Window, type: ScrollType) => {
  switch (type) {
    case ScrollType.HORIZONTAL:
      return getScrollXOf(element);
    case ScrollType.VERTICAL:
      return getScrollYOf(element);
  }
  return 0;
};

const setCurrentPositionBy = (element: HTMLElement | Window, type: ScrollType, value: number) => {
  switch (type) {
    case ScrollType.HORIZONTAL:
      setScrollXOf(element, value);
      break;
    case ScrollType.VERTICAL:
      setScrollYOf(element, value);
      break;
  }
};

const pSmoothScroll = (
  duration,
  element,
  to,
  type: ScrollType = ScrollType.VERTICAL,
  onFinish = noop
): SmoothScrollOptions => {
  if (!element) {
    // eslint-disable-next-line no-console
    console.error("Can't find element to do smooth scrolling");
    return null;
  }
  const start = getCurrentPositionBy(element, type);
  const change = to - start;
  const startDate = new Date().getTime();

  const animateScroll = () => {
    const currentDate = new Date().getTime();
    const currentTime = currentDate - startDate;
    setCurrentPositionBy(element, type, easeInOutQuad(currentTime, start, change, duration));

    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      setCurrentPositionBy(element, type, to);
      onFinish();
    }
  };
  animateScroll();

  return null;
};

const smoothScroll = (
  duration: number,
  element: any,
  to: any,
  type: ScrollType = ScrollType.VERTICAL,
  onFinish = noop
) => {
  setTimeout(() => {
    pSmoothScroll(duration, element, to, type, onFinish);
  }, 0);
};

export { smoothScroll };
