import styled from 'styled-components';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  shadow,
  ShadowProps,
  flexbox,
  FlexboxProps,
} from 'styled-system';

import { DOLThemeType } from './theme';

export interface BoxProps
  extends SpaceProps,
    ColorProps<DOLThemeType>,
    LayoutProps,
    PositionProps,
    BorderProps,
    TypographyProps,
    ShadowProps,
    FlexboxProps {}

export const Box = styled.div<BoxProps>(
  border,
  space,
  color,
  layout,
  position,
  typography,
  shadow,
  flexbox
);
