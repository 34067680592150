import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { ScrollType, smoothScroll } from '@doltech/utils/lib/smooth-scroll';
import cl from 'classnames';
import { noop } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

const SwiperMain = styled.div<{
  $gap: number;
  $disableFreeMode: boolean;
  $breakpoints: number[];
  $gridColumns?: any[];
}>`
  max-width: 100%;
  display: grid;

  grid-auto-columns: ${(p) => p.$gridColumns[0] || 'max-content'};
  ${(p) => fromScreen(p.$breakpoints?.[1] || 768)} {
    grid-auto-columns: ${(p) => p?.$gridColumns?.[1] || 'max-content'};
  }
  ${(p) => fromScreen(p.$breakpoints?.[2] || 1280)} {
    grid-auto-columns: ${(p) => p?.$gridColumns?.[2] || 'max-content'};
  }

  grid-auto-flow: column;
  gap: ${(p) => p.$gap || 24}px;

  overflow: ${(p) => (p.$disableFreeMode ? 'hidden' : 'auto')};
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const getStyledOfWidth = (input) => {
  if (!input) return '100%';
  if (typeof input === 'string') {
    return input;
  }
  return `${input}px`;
};

export const SwiperSlide = styled.div<{ $width: any[]; $breakpoints: number[] }>`
  height: 100%;
  width: ${(p) => getStyledOfWidth(p?.$width?.[0])};
  ${(p) => fromScreen(p.$breakpoints?.[1] || 768)} {
    width: ${(p) => getStyledOfWidth(p?.$width?.[1])};
  }
  ${(p) => fromScreen(p.$breakpoints?.[2] || 1280)} {
    width: ${(p) => getStyledOfWidth(p?.$width?.[2])};
  }
`;

interface NavigationProps {
  nextEl: string;
  prevEl: string;
  disabledClass: string;
}

interface SwiperProps {
  initialSlide?: number;
  children?: any[];
  gridColumns?: any[];
  breakpoints?: any[];
  slidesPerGroup?: number;
  gap?: number;
  navigation?: NavigationProps;
  disableFreeMode?: boolean;
  className?: string;

  onSlideChange?: ({ realIndex }) => void;
}

const insertClassName = (elms, className) => {
  for (let i = 0; i < elms.length; i++) {
    const elm = elms[i];
    if (!elm.classList.contains(className)) {
      elm.classList.add(className);
    }
  }
};

const removeClassName = (elms, className) => {
  for (let i = 0; i < elms.length; i++) {
    const elm = elms[i];
    elm.classList.remove(className);
  }
};

const addElmsEventListener = (elms, clickFn) => {
  for (let i = 0; i < elms.length; i++) {
    const elm = elms[i];
    elm.addEventListener('click', clickFn);
  }
};

const removeElmsEventListener = (elms, clickFn) => {
  for (let i = 0; i < elms.length; i++) {
    const elm = elms[i];
    elm.removeEventListener('click', clickFn);
  }
};

export const Swiper = React.forwardRef((props: SwiperProps, innerRef) => {
  const {
    gridColumns = ['max-content', 'max-content', 'max-content'],
    breakpoints = [0, 768, 1280],
    initialSlide = 0,
    children,
    gap = 24,
    slidesPerGroup = 1,
    navigation,
    disableFreeMode = false,
    onSlideChange = noop,
    className,
  } = props;
  const ref = React.useRef<HTMLDivElement>();
  const slideIndex = React.useRef(initialSlide);

  const slideNext = () => {
    if (!ref.current) return;
    const realIndex = Math.min(
      slideIndex.current + slidesPerGroup,
      (ref.current?.childNodes?.length || 100) - 1
    );

    const slideWidth = (ref.current.childNodes?.[0] as any)?.clientWidth || 0;
    // let scrollTo = Math.min(
    //   ref.current.scrollLeft + (slideWidth + gap) * slidesPerGroup,
    //   ref.current.scrollWidth
    // );
    // if (disableFreeMode) {
    //   scrollTo = Math.min((slideWidth + gap) * realIndex, ref.current.scrollWidth);
    // }
    const scrollTo = Math.min((slideWidth + gap) * realIndex, ref.current.scrollWidth);

    smoothScroll(300, ref.current, scrollTo, ScrollType.HORIZONTAL, () => {
      slideIndex.current = realIndex;
      onSlideChange({ realIndex });
    });
  };
  const slidePrev = () => {
    if (!ref.current) return;

    const realIndex = Math.max(slideIndex.current - Math.max(slidesPerGroup, 1), 0);
    const slideWidth = (ref.current.childNodes?.[0] as any)?.clientWidth || 0;
    // let scrollTo = Math.max(ref.current.scrollLeft - (slideWidth + gap) * slidesPerGroup, 0);
    // if (disableFreeMode) {
    //   scrollTo = Math.max((slideWidth + gap) * realIndex, 0);
    // }
    const scrollTo = Math.max((slideWidth + gap) * realIndex, 0);
    smoothScroll(300, ref.current, scrollTo, ScrollType.HORIZONTAL, () => {
      slideIndex.current = realIndex;
      onSlideChange({ realIndex });
    });
  };

  const slideTo = (toIndex, animationDelay = 300) => {
    if (!ref.current) return;

    const realIndex = Math.max(toIndex, 0);
    const slideWidth = (ref.current.childNodes?.[0] as any)?.clientWidth || 0;
    const scrollTo = Math.max((slideWidth + gap) * realIndex, 0);

    smoothScroll(animationDelay, ref.current, scrollTo, ScrollType.HORIZONTAL, () => {
      slideIndex.current = realIndex;
      onSlideChange({ realIndex });
    });
  };

  React.useImperativeHandle(
    innerRef,
    () =>
      ({
        swiper: {
          slideNext,
          slidePrev,
          slideTo,
        },
      } as any)
  );

  React.useEffect(() => {
    const mainElm = ref.current;
    const nextElms = document.querySelectorAll(navigation?.nextEl);
    const prevElms = document.querySelectorAll(navigation?.prevEl);
    const handleScroll = () => {
      if (!mainElm || !navigation || !nextElms || !prevElms) return;
      const isDisableNext = mainElm.scrollLeft + mainElm.clientWidth >= mainElm.scrollWidth;
      const isDisablePrev = mainElm.scrollLeft === 0;
      if (isDisableNext) {
        insertClassName(nextElms, navigation.disabledClass);
      } else {
        removeClassName(nextElms, navigation.disabledClass);
      }

      if (isDisablePrev) {
        insertClassName(prevElms, navigation.disabledClass);
      } else {
        removeClassName(prevElms, navigation.disabledClass);
      }
    };

    if (nextElms && prevElms) {
      addElmsEventListener(nextElms, slideNext);
      addElmsEventListener(prevElms, slidePrev);
      handleScroll();
    }

    if (mainElm) {
      if (initialSlide > 0) {
        const slideWidth = (ref.current.childNodes?.[0] as any)?.clientWidth || 0;
        ref.current.scrollLeft = slideWidth * initialSlide;
      }

      ref.current.addEventListener('scroll', handleScroll, true);
    }

    return () => {
      if (mainElm) {
        mainElm.removeEventListener('scroll', handleScroll, true);
      }
      if (nextElms && prevElms) {
        removeElmsEventListener(nextElms, slideNext);
        removeElmsEventListener(prevElms, slidePrev);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSlide, navigation]);

  return (
    <SwiperMain
      className={cl('swiper-main', className)}
      ref={ref}
      $gap={gap}
      $gridColumns={gridColumns}
      $breakpoints={breakpoints}
      $disableFreeMode={disableFreeMode}
    >
      {children}
    </SwiperMain>
  );
});
