import { responsiveOnBreakpoints } from '@doltech/core/lib/responsive/responsive.util';
import { IconChevronChevronLeft } from '@doltech/icons/IconChevronChevronLeft';
import { IconChevronChevronRight } from '@doltech/icons/IconChevronChevronRight';
import cl from 'classnames';
import { customAlphabet } from 'nanoid';
import * as React from 'react';
import styled from 'styled-components';

import { colorsV2 } from '../../../../figma/colors-v2';
import { Swiper, SwiperSlide } from '../../../../figma/DolSwiper/DolSwiper';
import { deviceBreakpoints } from '../../../../hooks/useDeviceQuery.hook';
import { CommonProps } from '../PageSectionModel';

interface NavigationResponsiveBreakPoint {
  breakpoint: number;
  /** px or percentagg, eg: 200x, 100% */
  top?: string;

  visible?: boolean;
}

const NAVIGATION_BUTTON_SIZE = 40;

const Container = styled.section<{
  customNavigation: NavigationResponsiveBreakPoint[];
  $gap: number;
}>`
  position: relative;

  .navigation-action {
    ${(p) =>
      responsiveOnBreakpoints(
        p.customNavigation,
        (item) => `display: ${item.visible ? 'grid' : 'none'}`
      )};
    color: ${colorsV2.white100};
    cursor: pointer;
    position: absolute;
    min-width: ${NAVIGATION_BUTTON_SIZE}px;
    min-height: ${NAVIGATION_BUTTON_SIZE}px;
    max-width: ${NAVIGATION_BUTTON_SIZE}px;
    max-height: ${NAVIGATION_BUTTON_SIZE}px;
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.32));
    place-items: center;
    border-radius: 50%;
    background-color: ${colorsV2.black100};
    font-size: 28px;
    line-height: 0;

    ${(p) =>
      responsiveOnBreakpoints(
        p.customNavigation,
        (item) => `top: calc(${item.top || '50%'} - ${NAVIGATION_BUTTON_SIZE / 2}px)`
      )};
    z-index: 2;

    &.next {
      right: -${NAVIGATION_BUTTON_SIZE / 2}px;
    }
    &.prev {
      left: -${NAVIGATION_BUTTON_SIZE / 2}px;
    }
    &.disabled {
      display: none;
    }
  }

  .grid-column-divider {
    column-gap: ${(p) => p.$gap * 2}px;

    ${SwiperSlide} {
      position: relative;

      &:not(:last-child):after {
        content: '';
        border-right: 1px solid ${colorsV2.black20};
        position: absolute;
        top: 0;
        bottom: 0;
        right: -${(p) => p.$gap}px;
      }
    }
  }

  .grid-row-divider {
    row-gap: ${(p) => p.$gap * 2}px;

    ${SwiperSlide} {
      position: relative;

      &:not(:last-child):after {
        content: '';
        border-bottom: 1px solid ${colorsV2.black20};
        position: absolute;
        bottom: -${(p) => p.$gap}px;
        left: 0;
        right: 0;
      }
    }
  }

  @media not screen and ${deviceBreakpoints.fromDesktop} {
    .swiper {
      overflow: visible;
    }
  }
`;

interface SwiperProps {}

export interface SwiperContentProps extends CommonProps {
  gap?: number;
  gridColumns?: any[];
  slideWidths?: any[];
  breakpoints?: any[];
  seeMoreUrl?: string;
  elementAtEnd?: React.ReactNode;
  slideOptions?: SwiperProps;
  withRowDivider?: boolean;
  withColumnDivider?: boolean;

  customNavigation?: NavigationResponsiveBreakPoint[];
  navigation?: any;
  className?: string;
}

const generateID = () => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  return customAlphabet(alphabet, 6)();
};

export interface SwiperRef {
  swiper: {
    slideNext?: () => void;
    slidePrev?: () => void;
    slideTo?: (slideTo, animationDelay) => void;
  };
}

export const SwiperContent = React.forwardRef<SwiperRef, SwiperContentProps>((props, ref) => {
  const {
    data,
    gridColumns,
    renderCard,
    breakpoints,
    slideWidths,
    seeMoreUrl,
    elementAtEnd,
    prefix = generateID(),
    gap = 24,
    customNavigation = [],
    withRowDivider,
    withColumnDivider,
    className,
    ...rest
  } = props;
  const swiperRef = React.useRef<SwiperRef>();
  const haveNavigation = customNavigation.length > 0;

  React.useImperativeHandle(ref, () => {
    return swiperRef.current;
  });

  return (
    <Container $gap={gap} customNavigation={customNavigation}>
      {haveNavigation && (
        <>
          <div
            onClick={() => {
              if (swiperRef.current) {
                swiperRef.current.swiper.slideNext();
              }
            }}
            className={cl('navigation-action', prefix, 'next')}
          >
            <IconChevronChevronRight />
          </div>
          <div
            onClick={() => {
              if (swiperRef.current) {
                swiperRef.current.swiper.slidePrev();
              }
            }}
            className={cl('navigation-action', prefix, 'prev')}
          >
            <IconChevronChevronLeft />
          </div>
        </>
      )}
      <Swiper
        className={cl(
          {
            'grid-column-divider': withColumnDivider,
            'grid-row-divider': withRowDivider,
          },
          className
        )}
        breakpoints={breakpoints}
        gridColumns={gridColumns}
        ref={swiperRef as any}
        gap={gap}
        navigation={
          haveNavigation
            ? {
                nextEl: `.${prefix}.next`,
                prevEl: `.${prefix}.prev`,
                disabledClass: 'disabled',
              }
            : undefined
        }
        {...rest}
      >
        {(data || []).map((item, index) => (
          <SwiperSlide
            key={item.id || index}
            $breakpoints={breakpoints}
            $width={slideWidths}
            className={cl('swiper-slide')}
          >
            {renderCard(item, index)}
          </SwiperSlide>
        ))}
        {elementAtEnd && <div slot="wrapper-end">{elementAtEnd}</div>}
      </Swiper>
    </Container>
  );
});
